import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper, Pagination } from '@mui/material';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { ClearCategorySearchAction, SetCategorySearchAction } from '../../actions/categorySearchAction';
import { categorySearchValues } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './Categories.css';
import { TabValue } from '../../constants/TabValue';
import { Category } from '../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { InfoTitles } from '../../constants/InfoTitles';
import { useNavigate } from 'react-router-dom';

export const Categories: React.FC<any> = (props) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, { name: string, includeInactive: boolean }>(categorySearchValues);
    const dispatch = useDispatch();
    const customerSkin = useCustomerSkin();
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_list_categories');
    }, [customerSkin])

    useEffect(() => {
        getCategories(searchValues, 1);
    }, [])

    async function getCategories(values: any, newPage: number, showWorkingMessage: boolean = true, showSuccessMessage: boolean = true) {
        const data = {
            Name: values.name,
            IncludeInactive: values.includeInactive,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        const response = await makeJSONPostRequest(ApiUrls.SEARCH_REPORT_CATEGORY_LIST, dispatch, data, showWorkingMessage, showWorkingMessage);

        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setCategories(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    }

    const selectCategory = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, category: Category) => {
        navigate(createRoute(ApplicationRoutes.EDIT_CATEGORY, { id: category.id, tab: TabValue.CATEGORY_ATTRIBUTES }))
    }

    const createNewCategory = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_CATEGORY, { id: -1, tab: TabValue.CATEGORY_ATTRIBUTES }))
    }

    const handleChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getCategories(searchValues, page, true, false);
    };

    async function performSearch(values: any) {
        dispatch(SetCategorySearchAction(values.Name, values.IncludeInactive));
        await getCategories({name: values.Name, includeInactive: values.IncludeInactive}, 1);
    }

    async function clearSearchFilters() {
        dispatch(ClearCategorySearchAction());
        await getCategories({ name: '', includeInactive: false }, 1);
    }

    const getInfoTitle = () => {
        return InfoTitles.LIST_CATEGORIES;
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="listCategories">
                <PageHeading nodes={[{label: "category_page_heading"}]} />
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Name: searchValues.name, IncludeInactive: searchValues.includeInactive }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="grid">
                                        <TextInput name="Name" label="category_filter_name" fullwidth={false} />
                                    </div>
                                    <div></div>
                                    <div className="flex">
                                        <span className="flexIndentLeft"></span>
                                        <CheckboxInput name="IncludeInactive" label="category_filter_include_inactive" />
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <Button id="addButton" variant="contained" color="primary" onClick={createNewCategory}>{getLabel('category_add_label')}</Button>
                <div>
                    <List id="resultList">
                        {categories.map((category: Category) => <Paper key={category.id}> <ListItem className="row" button onClick={(event) => selectCategory(event, category)}>
                            {category.active && <div className="name category">{category.name}</div>}
                            {!category.active && <div className="name category inactive-result">{getLabel('inactive_name', { name: category.name })}</div>}
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("category_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handleChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
