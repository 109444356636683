import React, { useEffect, useState } from 'react';
import './Login.css';
import {
    Container,
    Card,
    CardHeader,
    CardContent,
    Button,
} from '@mui/material';
import { getLabel } from '../../components/common/label/Label.library';
import { UnauthenticatedLayout } from '../../components/layouts/unauthenticated.layout/UnauthenticatedLayout';
import { TextInput } from '../../components/common/text.input/TextInput';
import { Formik } from 'formik';
import { makeErrorHandledAuthenticationRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { setPermissions, isAuthenticated, getAuthenticatedHomePage, setUserId, setUserName, setRoles, setUserCustomerShortCode, setUserCustomerName, setRestrictFilesAccess, setFileUploadAllowed, setUserEmail } from '../../services/auth/auth';
import { getUserPermissions, getUserRoles } from './Login.library';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { SetCustomerSkinsAction } from '../../actions/customerSkinsAction';
import { loginRedirectPathname } from '../../reducers/rootReducer';
import { AppState } from '../../store/configureStore';
import { ClearLoginRedirectAction } from '../../actions/loginRedirectAction';
import { SetLogOutAction } from '../../actions/authAction';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export const Login: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const redirectPathname = useSelector<AppState, string>(loginRedirectPathname);
    const customerSkin = useCustomerSkin();
    const featureFlags = useFeatureFlags();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = queryString.parse(location.search);
    const [ssoInProgress, setSsoInProgress] = useState(false);

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_login');
    }, [customerSkin])

    const login = async (values: any) => {
        const data = {
            Email: values.username,
            Password: values.password,
        };
        const response = await makeErrorHandledAuthenticationRequest(ApiUrls.LOGIN, dispatch, JSON.stringify(data));
        await processLoginResponse(response);
    };

    const ssoLogin = async () => {
        setSsoInProgress(true);
        try {
            // assuming already "logged in" with Entra(AAD), this is the following POST to finalize our hybrid login
            const response = await makeErrorHandledAuthenticationRequest(ApiUrls.SSO_LOGIN, dispatch, null);
            await processLoginResponse(response);
        } finally {
            setSsoInProgress(false);
        }
    };

    const ssoRedirect = () => {
        setSsoInProgress(true);
        // simple GET redirect, and once logged in with Entra(AAD), will redirect back here with ?sso param
        window.location.href = ApiUrls.SSO_LOGIN;
    };

    useEffect(() => {
        if ('sso' in queryParams) {
            const sameLocationWithoutParams = location.pathname;
            navigate(sameLocationWithoutParams, {replace: true});
            ssoLogin();
        }
    }, [queryParams.sso]);

    const processLoginResponse = async (response: any) => {
        const json = await response.json();
        dispatch(SetLogOutAction(false));

        setUserId(json.id);
        setUserName(json.name);
        setUserEmail(json.email);
        setRestrictFilesAccess(json.restrictFilesAccess);
        setFileUploadAllowed(json.allowFileUpload);
        // The only scenario that doesn't have the acting for selector is for a Customer role user with a single Customer associated
        if (!!json.customers && json.customers.length === 1) {
            setUserCustomerShortCode(json.customers[0].shortCode);
            setUserCustomerName(json.customers[0].name);
        }
        setRoles(getUserRoles(json.roles));
        setPermissions(getUserPermissions(json.roles));

        // Need to retrieve new set of customer skins based on logged in user
        const customerSkins = await makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER_SKINS_BY_HOSTNAME, { hostname: window.location.hostname }), dispatch, null, false, false);
        dispatch(SetCustomerSkinsAction(customerSkins.body.customerSkins));

        navigate(!!redirectPathname && redirectPathname !== ApplicationRoutes.LOGIN
            ? redirectPathname
            : getAuthenticatedHomePage()
        );
        dispatch(ClearLoginRedirectAction());
    };

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        // Validate username
        if (!values.username) {
            errors.username = getLabel('validation_message_required');
        }
        // Validate password
        if (!values.password) {
            errors.password = getLabel('validation_message_required');
        }
        return errors;
    };

    useEffect(() => {
        if (isAuthenticated()) {
            navigate(getAuthenticatedHomePage(), {replace: true});
        }
    }, []);

    useEffect(() => {
        if (queryParams.passwordSet) {
            dispatch(SetUserMessageSuccessAction('set_password_success_text'));
        }
    }, [queryParams.passwordSet, dispatch]);

    return (
        <UnauthenticatedLayout {...props}>
            <Formik
                initialValues={{ username: '', password: '' }}
                validate={validate}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                    login(values).finally(() => {
                        actions.setSubmitting(false);
                    });
                }}
            >
                {(props) => featureFlags && !ssoInProgress && (
                    <form onSubmit={props.handleSubmit}>
                        <Container maxWidth={false}>
                            <div className="login-container">
                                <Card className="login-panel">
                                    <CardHeader title={getLabel('login_heading')} />
                                    <CardContent className="login-card-content">
                                        <TextInput name="username" label="login_username_label" fullwidth={true} />
                                        <TextInput name="password" label="login_password_label" type="password" fullwidth={true} />
                                        <div className="submit-button">
                                            <Button variant="contained" color="primary" type="submit" fullWidth={true}
                                                disabled={!(props.dirty && props.isValid) || props.isSubmitting}>
                                                {getLabel('login_submit_button_label')}
                                            </Button>
                                            {featureFlags?.AADUsers &&
                                                <Button variant="contained" color="secondary" onClick={ssoRedirect} disabled={props.dirty}>
                                                    {getLabel('login_sso_button_label')}
                                                </Button>}
                                        </div>
                                    </CardContent>
                                </Card>
                                <div className="login-links">
                                    <Link to={ApplicationRoutes.RESET_PASSWORD}>{getLabel('reset_password_label')}</Link>
                                    {featureFlags?.AADUsers &&
                                        <a href={ApiUrls.SSO_LOGOUT}>{getLabel('logout_sso_label')}</a>}
                                </div>
                            </div>
                        </Container>
                    </form>
                )}
            </Formik>
        </UnauthenticatedLayout>
    );
};
