import { Button, Card, CardContent, List, ListItem, Pagination, Paper } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { ClearUserReportSearchAction, SetUserReportSearchAction, UserReportSearch } from '../../../actions/userReportSearchAction';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { SearchDefaults } from '../../../constants/SearchDefaults';
import { Customer, Report, User } from '../../../interfaces/ApiInterfaces';
import { reportCategoryDropdownValues, userReportSearchValues } from '../../../reducers/rootReducer';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { hasPermissions } from '../../../services/auth/auth';
import { AppState } from '../../../store/configureStore';
import { getLabel } from '../../common/label/Label.library';
import { SelectInput } from '../../common/select.input/SelectInput';
import { TextInput } from '../../common/text.input/TextInput';
import { Permissions } from '../../../constants/Permissions';
import { APIButton } from '../../common/button/APIButton';
import cloneDeep from 'lodash/cloneDeep';
import './UserReports.css';
import { SetReportCategoryDropdownAction } from '../../../actions/ReportCategoryDropdownAction';
import { customerDropdownValues } from '../../../reducers/rootReducer';

interface Props {
    user: User;
}

export const UserReports: React.FC<Props> = ({ user }) => {
    const [reports, setReports] = useState<Report[]>([]);
    const [userReportIds, updateUserReportIds] = useState<number[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const categoryValues = useSelector<AppState, any>(reportCategoryDropdownValues);
    const searchValues = useSelector<AppState, UserReportSearch>(userReportSearchValues);
    const customerValues = useSelector<AppState, any>(customerDropdownValues);
    const customerShortCodes = user.customerShortCodes;
    const canRestrictReportAccess = hasPermissions(Permissions.CAN_RESTRICT_REPORT_ACCESS);
    const dispatch = useDispatch();

    const getReports = async (values: any, newPage: number, showSuccessMessage: boolean = true, showWorkingMessage: boolean = true) => {
        const data = {
            Name: values.name,
            Category: values.category,
            CustomerShortCodes: !!values.customerShortCode && customerShortCodes.includes(values.customerShortCode) ? [values.customerShortCode] : customerShortCodes,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Category) delete data.Category;
        const response = await makeJSONPostRequest(
            ApiUrls.GET_REPORTS, dispatch, data, showSuccessMessage, showWorkingMessage
        );
        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        setReports(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const handleAdd = async (event:React.MouseEvent<HTMLDivElement, MouseEvent>, reportId: number) => {
        try {
            const response = await makeJSONPostRequest(
                createUrl(ApiUrls.CREATE_USER_REPORT_ASSOCIATION, { userId: user.id, reportId }), dispatch, null, true, true
            );
            
            if (response.response.ok) {
                var newUserReportIds = cloneDeep(userReportIds);
                newUserReportIds.push(reportId);
                updateUserReportIds(newUserReportIds);

                dispatch(SetUserMessageSuccessAction('user_report_success_create_association_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_report_failed_create_association_unauthorized'));
        }
    };

    const handleRemove = async (event:React.MouseEvent<HTMLDivElement, MouseEvent>, reportId: number) => {
        try {
            const response = await makeJSONPostRequest(
                createUrl(ApiUrls.REMOVE_USER_REPORT_ASSOCIATION, { userId: user.id, reportId }), dispatch, null, true, true
            );
            
            if (response.response.ok) {
                var newUserReportIds = cloneDeep(userReportIds);
                const indexOfReportToRemove = newUserReportIds.findIndex(associatedReportId => associatedReportId === reportId);
                if (indexOfReportToRemove !== -1) {
                    newUserReportIds.splice(indexOfReportToRemove, 1);
                    updateUserReportIds(newUserReportIds);
                }

                dispatch(SetUserMessageSuccessAction('user_report_success_remove_association_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_report_failed_remove_association_unauthorized'));
        }
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getReports(searchValues, page, false, true);
    };

    const performSearch = async (values: any) => {
        dispatch(SetUserReportSearchAction(values.Name, values.Category, values.CustomerShortCode));
        await getReports({ name: values.Name, category: values.Category, customerShortCode: values.CustomerShortCode }, 1, true, true);
    };

    const clearSearchFilters = async () => {
        dispatch(ClearUserReportSearchAction());
        await getReports({ name: '', category: '', customerShortCode: undefined}, 1, true, true);
    };

    useEffect(() => {
        const getCategories = async () => {
            var result = await makeJSONGetRequest(ApiUrls.GET_REPORT_CATEGORIES, dispatch, null, false, false);
            dispatch(SetReportCategoryDropdownAction(result.body));
        }
        if (categoryValues.length === 0) {
            getCategories();
        }
    }, [categoryValues, dispatch]);

    useEffect(() =>{
        const getUserReports = async () => {
            const response = await makeJSONGetRequest(
                createUrl(ApiUrls.GET_USER_REPORTS, { userId: user.id }), dispatch, null, false, false
            );
            const ids = response.body.map((r: Report) => r.id);
            updateUserReportIds(ids);
        };
        getUserReports();
    }, [user]);

    useEffect(() => {
        getReports(searchValues, 1, true, true);
    }, []);

    return (
        <Card>
            <CardContent className="user-reports">
                <Formik enableReinitialize={true}
                    initialValues={{ Name: searchValues.name, Category: searchValues.category, CustomerShortCode: !!searchValues.customerShortCode ? searchValues.customerShortCode : '' }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        performSearch(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <TextInput name="Name" label="report_filter_name" fullwidth={false} />
                                <SelectInput name="Category" label="report_filter_category" 
                                            values={categoryValues.map((category: any) =>
                                                <option key={category.name} value={category.name}>
                                                    {category.name}
                                                </option>
                                            )}
                                        />
                                {customerValues.length > 1 && 
                                    <SelectInput name="CustomerShortCode" label="user_filter_customer"
                                            values={customerValues.filter((value: Customer) => customerShortCodes.includes(value.shortCode)).map((c: Customer) =>
                                                <option key={c.name} value={c.shortCode}>
                                                    {c.name}
                                                </option>
                                            )} />}
                            </div>
                            <div className="flex">
                                <span className="flexIndentLeft"></span>
                                <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => {clearSearchFilters(); props.resetForm();}}>{getLabel('clear_search_filter_button_label')}</Button>
                                <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
                <List id="reportsList">
                        {reports.map((report: Report) => 
                            <Paper key={report.id}>
                                <ListItem className="row">
                                    <div className="user-report-row truncate">
                                        <div>
                                            {report.active && <div className="name">{report.name}</div>}
                                            {!report.active && <div className="name inactive-result">{getLabel('inactive_name', { name: report.name })}</div>}
                                            <div>
                                                {customerValues.length > 1 && <span className="reportCol"><div className="colHeader">{getLabel("report_list_customer_header")}</div><div>{report.customer.name}</div></span>}
                                                <span className="reportCol"><div className="colHeader">{getLabel("report_list_category_header")}</div><div>{(report.categories.map(category => category.name)).join(', ')}</div></span>
                                            </div>
                                        </div>
                                        <div className="user-report-buttons">
                                            {canRestrictReportAccess &&
                                            <>
                                            {userReportIds.includes(report.id)
                                                ? <APIButton className="remove-button button" type="button" variant="contained" color="primary" onClick={(e: any) => handleRemove(e, report.id)}>{getLabel("user_report_list_remove")}</APIButton>
                                                : <APIButton className="add-button button" type="button" variant="contained" color="primary" onClick={(e: any) => handleAdd(e, report.id)}>{getLabel("user_report_list_add")}</APIButton>
                                            }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </ListItem>
                            </Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("report_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handlePageChange} />}
            </CardContent>
        </Card>);
}
