import { Button, Card, CardContent, List, ListItem, Pagination, Paper } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchDefaults } from '../../../constants/SearchDefaults';
import { Category, User } from '../../../interfaces/ApiInterfaces';
import { APIButton } from '../../common/button/APIButton';
import { TextInput } from '../../common/text.input/TextInput';
import { getLabel } from '../../common/label/Label.library';
import { AppState } from '../../../store/configureStore';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { cloneDeep } from 'lodash';
import { UserCategorySearch, SetUserCategorySearchAction, ClearUserCategorySearchAction } from '../../../actions/userCategorySearchAction';
import { userCategorySearchValues } from '../../../reducers/rootReducer';
import './UserCategories.css';

interface Props {
    user: User;
}

export const UserCategories: React.FC<Props> = ({ user }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [userCategoryIds, updateUserCategoryIds] = useState<number[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, UserCategorySearch>(userCategorySearchValues);
    const canRestrictReportAccess = hasPermissions(Permissions.CAN_RESTRICT_REPORT_ACCESS);
    const dispatch = useDispatch();

    const getCategories = async (values: any, newPage: number, showSuccessMessage: boolean = true, showWorkingMessage: boolean = true) => {
        const data = {
            Name: values.name,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        const response = await makeJSONPostRequest(
            ApiUrls.SEARCH_REPORT_CATEGORY_LIST, dispatch, data, showSuccessMessage, showWorkingMessage
        );
        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        setCategories(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const handleAdd = async (event:React.MouseEvent<HTMLDivElement, MouseEvent>, categoryId: number) => {
        try {
            const response = await makeJSONPostRequest(
                createUrl(ApiUrls.CREATE_USER_REPORT_CATEGORY_ASSOCIATION, { userId: user.id, categoryId }), dispatch, null, true, true
            );
            
            if (response.response.ok) {
                var newUserCategoryIds = cloneDeep(userCategoryIds);
                newUserCategoryIds.push(categoryId);
                updateUserCategoryIds(newUserCategoryIds);

                dispatch(SetUserMessageSuccessAction('user_category_success_create_association_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_category_failed_create_association_unauthorized'));
        }
    };

    const handleRemove = async (event:React.MouseEvent<HTMLDivElement, MouseEvent>, categoryId: number) => {
        try {
            const response = await makeJSONPostRequest(
                createUrl(ApiUrls.REMOVE_USER_REPORT_CATEGORY_ASSOCIATION, { userId: user.id, categoryId }), dispatch, null, true, true
            );
            
            if (response.response.ok) {
                var newUserCategoryIds = cloneDeep(userCategoryIds);
                const indexOfCategoryToRemove = newUserCategoryIds.findIndex(associatedCategoryId => associatedCategoryId === categoryId);
                if (indexOfCategoryToRemove !== -1) {
                    newUserCategoryIds.splice(indexOfCategoryToRemove, 1);
                    updateUserCategoryIds(newUserCategoryIds);
                }

                dispatch(SetUserMessageSuccessAction('user_category_success_remove_association_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_category_failed_remove_association_unauthorized'));
        }
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getCategories(searchValues, page, false, true);
    };

    const performSearch = async (values: any) => {
        dispatch(SetUserCategorySearchAction(values.Name));
        await getCategories({ name: values.Name }, 1, true, true);
    };

    const clearSearchFilters = async () => {
        dispatch(ClearUserCategorySearchAction());
        await getCategories({ name: '' }, 1, true, true);
    };

    useEffect(() =>{
        const getUserCategories = async () => {
            const response = await makeJSONGetRequest(
                createUrl(ApiUrls.GET_USER_REPORT_CATEGORIES, { userId: user.id }), dispatch, null, false, false
            );
            const ids = response.body.map((c: Category) => c.id);
            updateUserCategoryIds(ids);
        };
        getUserCategories();
    }, [user]);

    useEffect(() => {
        getCategories(searchValues, 1, true, true);
    }, []);

    return (
        <Card>
            <CardContent className="user-categories">
                <Formik enableReinitialize={true}
                    initialValues={{ Name: searchValues.name }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        performSearch(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <TextInput name="Name" label="category_filter_name" fullwidth={false} />
                            </div>
                            <div className="flex">
                                <span className="flexIndentLeft"></span>
                                <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => {clearSearchFilters(); props.resetForm();}}>{getLabel('clear_search_filter_button_label')}</Button>
                                <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
                <List id="categoriesList">
                        {categories.map((category: Category) => 
                            <Paper key={category.id}>
                                <ListItem className="row">
                                    <div className="user-category-row truncate">
                                        <div>
                                            {category.active && <div className="name category">{category.name}</div>}
                                            {!category.active && <div className="name category inactive-result">{getLabel('inactive_name', { name: category.name })}</div>}
                                        </div>
                                        <div className="user-category-buttons">
                                            {canRestrictReportAccess &&
                                            <>
                                            {userCategoryIds.includes(category.id)
                                                ? <APIButton className="remove-button button" type="button" variant="contained" color="primary" onClick={(e: any) => handleRemove(e, category.id)}>{getLabel("user_category_list_remove")}</APIButton>
                                                : <APIButton className="add-button button" type="button" variant="contained" color="primary" onClick={(e: any) => handleAdd(e, category.id)}>{getLabel("user_category_list_add")}</APIButton>
                                            }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </ListItem>
                            </Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("category_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handlePageChange} />}
            </CardContent>
        </Card>);
}
