export class ApiUrls {
    public static readonly GET_APP_VERSION = 'api/Management/version';
    public static readonly GET_APP_FEATURE_FLAGS = 'api/Management/featureFlags';
    public static readonly GET_APP_INACTIVITY_TIMEOUT = 'api/Management/inactivityTimeout';
    public static readonly GET_ENVIRONMENT_INDICATOR = 'api/Management/environmentIndicator';
    public static readonly GET_APP_UPLOAD_RESTRICTIONS = 'api/Management/uploadRestrictions';

    public static readonly LOGIN = 'api/User/Login';
    public static readonly LOGOUT = 'api/User/Logout';
    public static readonly SSO_LOGIN = 'api/User/SsoLogin';
    public static readonly SSO_LOGOUT = 'api/User/SsoLogout';
    public static readonly SEND_FORGOT_PASSWORD_EMAIL = 'api/User/ForgotPassword';
    public static readonly SEND_RESET_PASSWORD_EMAIL = 'api/User/RequestPasswordReset';
    public static readonly SEND_ONBOARDING_EMAIL = 'api/User/SendOnboardingEmail';
    public static readonly PASSWORD_RESET = 'api/User/PasswordReset';

    public static readonly GET_USER = 'api/User/:userId';
    public static readonly EDIT_USER = 'api/User/:userId';
    public static readonly REGISTER_USER = 'api/User/RegisterUser';
    public static readonly GET_USERS = '/api/User/GetUsers';
    public static readonly GET_ROLES = '/api/User/GetRoles';
    public static readonly GET_CUSTOMER_AND_CUSTOMER_ADMIN_EMAILS = '/api/User/:customerShortCode/GetCustomerAndCustomerAdminEmails';
    public static readonly CREATE_USER_CUSTOMER_ASSOCIATION = '/api/User/:userId/CreateUserCustomerAssociation/:customerShortCode';
    public static readonly REMOVE_USER_CUSTOMER_ASSOCIATION = '/api/User/:userId/RemoveUserCustomerAssociation/:customerShortCode';
    public static readonly GET_USER_REPORTS = '/api/User/:userId/GetUserReports';
    public static readonly CREATE_USER_REPORT_ASSOCIATION = '/api/User/:userId/CreateUserReportAssociation/:reportId';
    public static readonly REMOVE_USER_REPORT_ASSOCIATION = '/api/User/:userId/RemoveUserReportAssociation/:reportId';
    public static readonly GET_USER_REPORT_CATEGORIES = '/api/User/:userId/GetUserReportCategories';
    public static readonly CREATE_USER_REPORT_CATEGORY_ASSOCIATION = '/api/User/:userId/CreateUserReportCategoryAssociation/:categoryId';
    public static readonly REMOVE_USER_REPORT_CATEGORY_ASSOCIATION = '/api/User/:userId/RemoveUserReportCategoryAssociation/:categoryId';

    public static readonly GET_REPORTS = '/api/Report/GetReports';
    public static readonly GET_REPORT = '/api/Report/:reportId';
    public static readonly GET_REPORT_TILE = '/api/Report/:reportId/:tileId';
    public static readonly DOWNLOAD_REPORT = '/api/Report/:reportId/Download';
    public static readonly GET_REPORT_VALUES = '/api/Report/:reportId/values';
    public static readonly CREATE_REPORT = '/api/Report/Create/:customerShortCode';
    public static readonly UPDATE_REPORT = '/api/Report/Update';
    public static readonly GET_REPORT_CATEGORIES = '/api/Report/GetReportCategories';
    public static readonly GET_REPORT_CATEGORY_LIST = '/api/Report/GetReportCategoryList'; //TODO: remove
    public static readonly SEARCH_REPORT_CATEGORY_LIST = '/api/Report/SearchReportCategoryList'; //TODO: remove
    public static readonly CREATE_CATEGORY = '/api/Report/CreateCategory';
    public static readonly UPDATE_CATEGORY = '/api/Report/UpdateCategory';
    public static readonly GET_CATEGORY = '/api/Report/GetCategory/:categoryId';
    public static readonly GET_CATEGORIES_BY_REPORT = '/api/Report/GetCategoriesByReport/:reportId';
    public static readonly ADD_CATEGORY_TO_REPORT = '/api/Report/AddCategoryToReport';
    public static readonly REMOVE_CATEGORY_FROM_REPORT = '/api/Report/RemoveCategoryFromReport';

    public static readonly GET_USER_NOTIFICATIONS = '/api/Notification/GetUserAlerts';
    public static readonly GET_CUSTOMER_NOTIFICATIONS = '/api/Notification/GetCustomerAlerts/:customerShortCode';
    public static readonly DISMISS_USER_NOTIFICATION = '/api/Notification/DismissForUser/:notificationId';

    public static readonly GET_INFO = '/api/Info/:infoId';
    public static readonly GET_INFO_LIST = '/api/Info/GetInfoList';
    public static readonly GET_INFO_ID_BY_TITLE = '/api/Info/GetInfoIdByTitle';

    public static readonly GET_CUSTOMER_SKINS_BY_HOSTNAME = '/api/Customer/GetCustomerSkin/:hostname';
    public static readonly GET_ALL_CUSTOMERS = '/api/Customer/GetCustomers';
    public static readonly GET_ACTIVE_CUSTOMERS = '/api/Customer/GetAuthenticatedUserCustomers';
    public static readonly CREATE_CUSTOMER = '/api/Customer/Create';
    public static readonly EDIT_CUSTOMER = '/api/Customer/:customerShortCode';
    public static readonly GET_CUSTOMER = '/api/Customer/:customerShortCode';
    public static readonly GET_CUSTOMER_ATTRIBUTES = '/api/Customer/GetAttributes/:customerShortCode';
    public static readonly UPDATE_CUSTOMER_ATTRIBUTES = '/api/Customer/UpdateAttributes/:customerShortCode';

    public static readonly CAN_CONNECT_TO_STORAGE = '/api/BlobStorage/CanConnectToStorage/:customerShortCode';
    public static readonly GET_CONTAINERS = '/api/BlobStorage/GetContainers/:customerShortCode/:isForUpload';
    public static readonly SEARCH_FILES = '/api/BlobStorage/SearchBlobStorage';
    public static readonly UPLOAD_TO_BLOB_STORAGE = '/api/BlobStorage/UploadToBlobStorage';
    public static readonly DELETE_FROM_BLOB_STORAGE = '/api/BlobStorage/DeleteBlob';
    public static readonly GET_BLOB = '/api/BlobStorage/GetBlob';
    public static readonly GET_BLOB_INFO = '/api/BlobStorage/GetBlobInfo';
    public static readonly UPDATE_BLOB = '/api/BlobStorage/UpdateBlob';
}

export function createUrl(url: string, params: { [key: string]: any }): string {
    Object.keys(params).forEach((key) => {
        let value = String(params[key]);
        if (value.includes('/')) // if sth like '../../another/endpoint' comes in one of the params (in a client-side CSRF attack)
            throw "Invalid URL parameter value!";
        url = url.replace(':' + key, value);
    });
    return url;
}