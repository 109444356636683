import moment from 'moment-timezone';
import React, { FC, Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SetLoginRedirectAction } from './actions/loginRedirectAction';
import { ClearUserMessageAction } from './actions/userMessageAction';
import { PrivateRoute } from './components/common/private.route/PrivateRoute';
import { Theme } from './components/theme/Theme';
import { Permissions } from './constants/Permissions';
import { ACTING_FOR_SHORTCODE, ApplicationRoutes } from './constants/ApplicationRoutes';
import { useFeatureFlags } from './hooks/useFeatureFlags';
import { isLoggedOut, userMessageText } from './reducers/rootReducer';
import { isAuthenticated, logOut, hasRestrictedFilesAccess, isFileUploadAllowed } from './services/auth/auth';
import { AppState } from './store/configureStore';
import { Categories } from './views/Categories/Categories';
import { EditCategory } from './views/Categories/categories.edit/EditCategories';
import { Customers } from './views/customers/Customers';
import { EditCustomer } from './views/customers/customers.edit/EditCustomer';
import { ListInfo } from './views/info/ListInfo';
import { ViewInfo } from './views/info/ViewInfo';
import { Login } from './views/login/Login';
import { ResetPassword } from './views/login/ResetPassword';
import { NotFound } from './views/not.found/NotFound';
import { Reports } from './views/reports/Reports';
import { EditReport } from './views/reports/reports.edit/EditReport';
import { ViewReport } from './views/reports/reports.view/ViewReport';
import { SetPassword } from './views/set.password/SetPassword';
import { EditUser } from './views/users/EditUser';
import { Users } from './views/users/Users';
import { FileUpload } from './views/file/file.upload/FileUpload';
import { FileEdit } from './views/file/file.edit/FileEdit';
import { ListFiles } from './views/file/file.download/ListFiles';
import { ApiUrls } from './constants/ApiUrls';
import { makeJSONGetRequest } from './services/ajax/ajax';

interface PrivateRoute {
    path: string;
    element: FC<any>;
    permissions?: string[];
}

interface FeatureFlaggedPrivateRoute extends PrivateRoute {
    isFeatureFlagEnabled: boolean;
}

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const featureFlags = useFeatureFlags();
    const message = useSelector<AppState, string>(userMessageText);
    const logOutStatus = useSelector<AppState, boolean>(isLoggedOut);
    const { REACT_APP_TIMEZONE } = process.env;
    const [environmentIndicator, setEnvironmentIndicator] = useState<string>("");

    const privateRoutes: PrivateRoute[] = [
        { path: ApplicationRoutes.USERS, element: Users, permissions: [Permissions.CAN_VIEW_USERS, Permissions.CAN_VIEW_CUSTOMER_USERS_ONLY, Permissions.CAN_VIEW_CUSTOMER_AND_CUSTOMER_ADMIN_USERS_ONLY] },
        { path: ApplicationRoutes.EDIT_USER, element: EditUser, permissions: [Permissions.CAN_VIEW_USERS, Permissions.CAN_VIEW_CUSTOMER_USERS_ONLY, Permissions.CAN_VIEW_CUSTOMER_AND_CUSTOMER_ADMIN_USERS_ONLY] },
        { path: ApplicationRoutes.CUSTOMERS, element: Customers, permissions: [Permissions.CAN_SEE_CUSTOMERS_PAGE] },
        { path: ApplicationRoutes.EDIT_CUSTOMERS, element: EditCustomer, permissions: [Permissions.CAN_SEE_CUSTOMERS_PAGE] },
        { path: ApplicationRoutes.REPORTS, element: Reports, permissions: [Permissions.CAN_VIEW_REPORTS, Permissions.CAN_VIEW_LIMITED_REPORTS] },
        { path: ApplicationRoutes.VIEW_REPORT, element: ViewReport, permissions: [Permissions.CAN_VIEW_REPORTS, Permissions.CAN_VIEW_LIMITED_REPORTS] },
        { path: ApplicationRoutes.EDIT_REPORT, element: EditReport, permissions: [Permissions.CAN_VIEW_REPORT_PARAMETERS] },
        { path: ApplicationRoutes.CATEGORIES, element: Categories, permissions: [Permissions.CAN_VIEW_CATEGORY_PARAMETERS] },
        { path: ApplicationRoutes.EDIT_CATEGORY, element: EditCategory, permissions: [Permissions.CAN_VIEW_CATEGORY_PARAMETERS] }
    ];
    const featureFlaggedPrivateRoutes: FeatureFlaggedPrivateRoute[] = [
        { isFeatureFlagEnabled: !featureFlags || featureFlags.Info, path: ApplicationRoutes.VIEW_INFO, element: ViewInfo },
        { isFeatureFlagEnabled: !featureFlags || featureFlags.Info, path: ApplicationRoutes.INFO, element: ListInfo },
        { isFeatureFlagEnabled: !featureFlags || featureFlags.Files, path: ApplicationRoutes.LIST_FILES, element: ListFiles, permissions: [Permissions.CAN_VIEW_PRIVILEGED_CONTAINER, Permissions.CAN_VIEW_CONTAINER] },
        { isFeatureFlagEnabled: (!featureFlags || featureFlags.Files) && isFileUploadAllowed(), path: ApplicationRoutes.UPLOAD_FILE, element: FileUpload, permissions: [Permissions.CAN_UPLOAD_TO_PRIVILEGED_CONTAINER, Permissions.CAN_UPLOAD_TO_CONTAINER] },
        { isFeatureFlagEnabled: (!featureFlags || featureFlags.Files) && !hasRestrictedFilesAccess(), path: ApplicationRoutes.EDIT_FILE, element: FileEdit, permissions: [Permissions.CAN_UPLOAD_TO_PRIVILEGED_CONTAINER, Permissions.CAN_UPLOAD_TO_CONTAINER] }
    ];

    useEffect(() => {
        moment.tz.setDefault(REACT_APP_TIMEZONE);
        console.log(moment().format());
    }, []);

    useEffect(() => {
        if (message && message.length > 0) {
            dispatch(ClearUserMessageAction());
        }
    }, [location.pathname]);

    useLayoutEffect(() => {
        if (logOutStatus && isAuthenticated()) {
            logOut(dispatch, false);
            navigate(ApplicationRoutes.LOGIN);
            dispatch(SetLoginRedirectAction(location.pathname));
        }
    }, [dispatch, logOutStatus]);

    useEffect(() => {

        const getEnvironmentIndicator = async () => {
            var response = await makeJSONGetRequest(ApiUrls.GET_ENVIRONMENT_INDICATOR, dispatch, null, false, false);
            setEnvironmentIndicator(response.body.environmentIndicator);
        }
        getEnvironmentIndicator();
    }, []);

    const renderPrivateRoutes = (): JSX.Element[] => {
        const enabledPrivateRoutes = featureFlaggedPrivateRoutes.filter((featureFlaggedRoute) => featureFlaggedRoute.isFeatureFlagEnabled);
        const renderedPrivateRoutes = privateRoutes.concat(enabledPrivateRoutes);
        return renderedPrivateRoutes.map((privateRoute) => {
            return <Fragment key={privateRoute.path}>
                <Route path={privateRoute.path} element={<PrivateRoute Component={privateRoute.element} permissions={privateRoute.permissions} />} />
                <Route path={privateRoute.path + ACTING_FOR_SHORTCODE} element={<PrivateRoute Component={privateRoute.element} permissions={privateRoute.permissions} />} />
            </Fragment>
        });
    };

    return (
        <>
            {environmentIndicator && <div className="environment-indicator" style={{ backgroundColor: environmentIndicator }} />}
            <Theme>
                <Routes>
                    <Route path={ApplicationRoutes.ROOT_ROUTE} element={<Login />} />
                    <Route path={ApplicationRoutes.LOGIN} element={<Login />} />
                    <Route path={ApplicationRoutes.SET_PASSWORD} element={<SetPassword />} />
                    <Route path={ApplicationRoutes.RESET_PASSWORD} element={<ResetPassword />} />
                    {renderPrivateRoutes()}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Theme>
        </>
    );
}

export default App;
