import moment from 'moment-timezone';
import { DateTime } from '../../constants/DateTime';

export const formatDate = (value: string) => {
  const date = moment(value);
  const output = moment(date).format(DateTime.DISPLAY_DATE);
  return output;
};

export const formatDateTime = (value: string) => {
    const date = moment(value);
    const output = moment(date).format(DateTime.DISPLAY_DATE_WITH_TIME);
    return output;
};
