import {
  Severity,
  UserMessage,
  UserMessageActionTypes,
  SET_USER_MESSAGE_SUCCESS,
  SET_USER_MESSAGE_ERROR,
  SET_USER_MESSAGE_WAITING,
  CLEAR_USER_MESSAGE,
} from '../actions/userMessageAction';

const initialState: UserMessage = {
  severity: Severity.Success,
  message: '',
};

export const userMessage = (
  state: UserMessage = initialState,
  action: UserMessageActionTypes
): UserMessage => {
  switch (action.type) {
    case SET_USER_MESSAGE_SUCCESS:
      return { severity: Severity.Success, message: action.userMessage.message };
    case SET_USER_MESSAGE_ERROR:
      return { severity: Severity.Error, message: action.userMessage.message };
    case SET_USER_MESSAGE_WAITING:
      return { severity: Severity.Waiting, message: action.userMessage.message };
    case CLEAR_USER_MESSAGE:
      return initialState;
    default: {
      return state;
    }
  }
};

export const getSeverity = (state: UserMessage) => state.severity;
export const getUserMessage = (state: UserMessage) => state.message;
