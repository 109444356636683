export enum Severity {
    Success = 'success',
    Error = 'error',
    Waiting = 'info'
}

export interface UserMessage {
    severity: Severity;
    message: string;
}

export const SET_USER_MESSAGE_SUCCESS = "SET_USER_MESSAGE_SUCCESS";
export const SET_USER_MESSAGE_ERROR = "SET_USER_MESSAGE_ERROR";
export const SET_USER_MESSAGE_WAITING = "SET_USER_MESSAGE_WAITING";
export const CLEAR_USER_MESSAGE = "CLEAR_USER_MESSAGE";

interface SetUserMessageSuccess {
    type: typeof SET_USER_MESSAGE_SUCCESS;
    userMessage: UserMessage;
}

interface SetUserMessageError {
    type: typeof SET_USER_MESSAGE_ERROR;
    userMessage: UserMessage;
}

interface SetUserMessageWaiting {
    type: typeof SET_USER_MESSAGE_WAITING;
    userMessage: UserMessage;
}

interface ClearUserMessage {
    type: typeof CLEAR_USER_MESSAGE;
}

export type UserMessageActionTypes = SetUserMessageSuccess | SetUserMessageError | SetUserMessageWaiting | ClearUserMessage;

export const SetUserMessageSuccessAction = (message: string): SetUserMessageSuccess => ({
    type: SET_USER_MESSAGE_SUCCESS,
    userMessage: {severity: Severity.Success, message: message}
});

export const SetUserMessageErrorAction = (message: string): SetUserMessageError => ({
    type: SET_USER_MESSAGE_ERROR,
    userMessage: {severity: Severity.Error, message: message}
});

export const SetUserMessageWaitingAction = (message: string): SetUserMessageWaiting => ({
    type: SET_USER_MESSAGE_WAITING,
    userMessage: {severity: Severity.Waiting, message: message}
});

export const ClearUserMessageAction = (): ClearUserMessage => ({
    type: CLEAR_USER_MESSAGE
});