export class Permissions {
    public static readonly CAN_CREATE_CUSTOMER = "CAN_CREATE_CUSTOMER";
    public static readonly CAN_UPDATE_CUSTOMER = "CAN_UPDATE_CUSTOMER";
    public static readonly CAN_VIEW_CUSTOMERS = "CAN_VIEW_CUSTOMERS";
    public static readonly CAN_VIEW_MY_CUSTOMERS = "CAN_VIEW_MY_CUSTOMERS";
    public static readonly CAN_SEE_CUSTOMERS_PAGE = "CAN_SEE_CUSTOMERS_PAGE";
    public static readonly CAN_CREATE_USER = "CAN_CREATE_USER";
    public static readonly CAN_UPDATE_USER = "CAN_UPDATE_USER";
    public static readonly CAN_VIEW_USERS = "CAN_VIEW_USERS";
    public static readonly CAN_ACT_FOR_CUSTOMER = "CAN_ACT_FOR_CUSTOMER";
    public static readonly CAN_ACT_FOR_ONLY_MY_CUSTOMERS = "CAN_ACT_FOR_ONLY_MY_CUSTOMERS";
    public static readonly CAN_VIEW_REPORTS = "CAN_VIEW_REPORTS";
    public static readonly CAN_VIEW_CUSTOMER_USERS_ONLY = "CAN_VIEW_CUSTOMER_USERS_ONLY";
    public static readonly CAN_LOGIN_IF_ACTIVE_CUSTOMER = "CAN_LOGIN_IF_ACTIVE_CUSTOMER";
    public static readonly CAN_REQUEST_PASSWORD_RESET = "CAN_REQUEST_PASSWORD_RESET";
    public static readonly CAN_VIEW_REPORT_PARAMETERS = "CAN_VIEW_REPORT_PARAMETERS";
    public static readonly CAN_CREATE_REPORT_PARAMETERS = "CAN_CREATE_REPORT_PARAMETERS";
    public static readonly CAN_UPDATE_REPORT_PARAMETERS = "CAN_UPDATE_REPORT_PARAMETERS";
    public static readonly CAN_VIEW_CATEGORY_PARAMETERS = "CAN_VIEW_CATEGORY_PARAMETERS";
    public static readonly CAN_CREATE_CATEGORY_PARAMETERS = "CAN_CREATE_CATEGORY_PARAMETERS";
    public static readonly CAN_UPDATE_CATEGORY_PARAMETERS = "CAN_UPDATE_CATEGORY_PARAMETERS";
    public static readonly CAN_VIEW_LIMITED_REPORTS = "CAN_VIEW_LIMITED_REPORTS";
    public static readonly CAN_RESTRICT_REPORT_ACCESS = "CAN_RESTRICT_REPORT_ACCESS";
    public static readonly CAN_REUSE_EMAIL = "CAN_REUSE_EMAIL";
    public static readonly CAN_ONLY_ACCESS_DEMO_CUSTOMERS = "CAN_ONLY_ACCESS_DEMO_CUSTOMERS";
    public static readonly CAN_UPLOAD_TO_PRIVILEGED_CONTAINER = "CAN_UPLOAD_TO_PRIVILEGED_CONTAINER";
    public static readonly CAN_VIEW_PRIVILEGED_CONTAINER = "CAN_VIEW_PRIVILEGED_CONTAINER";
    public static readonly CAN_UPLOAD_TO_CONTAINER = "CAN_UPLOAD_TO_CONTAINER";
    public static readonly CAN_VIEW_CONTAINER = "CAN_VIEW_CONTAINER";
    public static readonly CAN_FILTER_CUSTOMERS_DEMO = "CAN_FILTER_CUSTOMERS_DEMO";
    public static readonly CAN_SEND_WELCOME_EMAIL = "CAN_SEND_WELCOME_EMAIL";
    public static readonly CAN_PERMANENTLY_DELETE_FILE = "CAN_PERMANENTLY_DELETE_FILE";
    public static readonly CAN_VIEW_DELETE_CONTAINER = "CAN_VIEW_DELETE_CONTAINER";
    public static readonly CAN_VIEW_ALL_FILES = "CAN_VIEW_ALL_FILES";
    public static readonly CAN_VIEW_REPORT_ACCESS = "CAN_VIEW_REPORT_ACCESS";
    public static readonly CAN_VIEW_CUSTOMER_AND_CUSTOMER_ADMIN_USERS_ONLY = "CAN_VIEW_CUSTOMER_AND_CUSTOMER_ADMIN_USERS_ONLY";
    public static readonly CAN_VIEW_PRIVILEGED_ATTRIBUTES = "CAN_VIEW_PRIVILEGED_ATTRIBUTES";
}
