import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik, FormikTouched } from 'formik';
import { Button, CardContent, Card, Checkbox, FormControl, FormGroup, FormLabel, FormControlLabel, FormHelperText } from '@mui/material';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { Permissions } from '../../constants/Permissions';
import { AppState } from '../../store/configureStore';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { useDispatch, useSelector } from 'react-redux';
import { roleDropdownValues } from '../../reducers/rootReducer';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './UserAttributes.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { User, UserRoles } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { TabValue } from '../../constants/TabValue';
import { StatesDropdown } from '../common/states.dropdown/StatesDropdown';
import { RoleIds } from '../../constants/RoleIds';
import { getUserId, hasPermissions, logOut } from '../../services/auth/auth';
import { isEmpty, isValidEmail } from '../../services/validate/validate';

interface Props {
    user: User;
    updateHasCustomerRole: any;
    getUser: any;
    userHasPermission: any;
    needToAssociateWithCustomers: any;
}

export const UserAttributes: React.FC<Props> = ({ user, updateHasCustomerRole, getUser, userHasPermission, needToAssociateWithCustomers }) => {
    const roleValues = useSelector<AppState, any>(roleDropdownValues);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAdd = user.id === -1;
    const isCustomerAdminUser = !hasPermissions(Permissions.CAN_VIEW_USERS);

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (isEmpty(values.DisplayName)) {
            errors.DisplayName = getLabel('validation_message_required');
        }
        if (isEmpty(values.Email)) {
            errors.Email = getLabel('validation_message_required');
        } else if (!isValidEmail(values.Email)) {
            errors.Email = getLabel('validation_message_email');
        }
        if (values.RoleIds.length === 0) {
            errors.RoleIds = getLabel('validation_message_required');
        } else if (values.RoleIds.includes(RoleIds.DEMO)) {
            if (!values.RoleIds.includes(RoleIds.CUSTOMER) && 
                !values.RoleIds.includes(RoleIds.CUSTOMER_ADMIN)
            ) {
                errors.RoleIds = getLabel('validation_message_demo_role_needs_other_valid_role');
            } else if (values.RoleIds.some((roleId: number) =>
                roleId !== RoleIds.CUSTOMER &&
                roleId !== RoleIds.CUSTOMER_ADMIN &&
                roleId !== RoleIds.DEMO)
            ) {
                errors.RoleIds = getLabel('validation_message_demo_role_other_invalid_role');
            }
        }
        return errors;
    }

    const canAssociateWithReports = (roleIds: number[]) : boolean => {
        return userHasPermission(Permissions.CAN_VIEW_REPORTS, roleIds) ? false : userHasPermission(Permissions.CAN_VIEW_LIMITED_REPORTS, roleIds);
    }

    const canRestrictFilesAccess = (roleIds: number[]) : boolean => {
        const customerAndDemoRoles = [RoleIds.CUSTOMER, RoleIds.DEMO]
        return roleIds.includes(RoleIds.CUSTOMER) && roleIds.every(role => customerAndDemoRoles.includes(role));
    }

    const formClean = (touched: FormikTouched<{ DisplayName: any; Email: any; IsActive: any; City: any; State: any; RestrictReportView: any; RestrictFilesAccess: any; AllowFileUpload: any; RoleIds: any; }>) => {
        return (touched.DisplayName === undefined &&
            touched.Email === undefined &&
            touched.IsActive === undefined &&
            touched.City === undefined &&
            touched.State === undefined &&
            touched.RestrictReportView === undefined &&
            touched.RestrictFilesAccess === undefined &&
            touched.AllowFileUpload === undefined &&
            touched.RoleIds === undefined);
    }

    const updateUserRoles = (
        setFieldValue: (field: string, value: any) => void,
        setFieldTouched: (field: string, isTouched?: boolean) => void
    ) => function (roleIds: number[]) {
        setFieldValue('RoleIds', roleIds);
        setFieldTouched('RoleIds', true);
        setFieldValue('RestrictReportView', canAssociateWithReports(roleIds));
        if (!canRestrictFilesAccess(roleIds)) {
            setFieldValue('RestrictFilesAccess', false);
        }
    }

    const getRoles = (updateUserRoles: (roleIds: number[]) => void) => {
        if (!roleValues) return;
        var userRoles: UserRoles[] = roleValues.body;
        if (isCustomerAdminUser) {
            userRoles = isAdd
                ? userRoles.filter(role => role.id === RoleIds.CUSTOMER || role.id === RoleIds.DEMO)
                : userRoles.filter(role => user.roleIds.includes(role.id));
        }
        return (
            <>
                {userRoles.map(role => {
                    return <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={user.roleIds.includes(role.id)}
                                name={role.name}
                                onChange={(event) => handleRoleCheck(event, updateUserRoles)}
                                disabled={isCustomerAdminUser}
                            />
                        }
                        label={role.name}
                        key={role.id}
                    />
                })}
            </>
        );
    }

    const handleRoleCheck = (event: React.ChangeEvent<HTMLInputElement>, updateUserRoles: (roleIds: number[]) => void) => {
        if (!!roleValues) {
            var roleIds = user.roleIds;
            var changedRole = roleValues.body.find((role: UserRoles) => role.name === event.target.name);
            if (event.target.checked) {
                roleIds.push(changedRole.id);
                // Demo Role can't be solitary
                if (changedRole.id === RoleIds.DEMO && !roleIds.includes(RoleIds.CUSTOMER) && !roleIds.includes(RoleIds.CUSTOMER_ADMIN)) {
                    roleIds.push(RoleIds.CUSTOMER_ADMIN);
                }
            } else {
                var index = roleIds.findIndex((id: number) => id === changedRole.id);
                if (index !== -1) {
                    roleIds.splice(index, 1);
                }
            }
            updateUserRoles(roleIds);
        }
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.USERS));
    }

    const saveUser = async (values: any) => {
        var restrictFilesAccessValue = canRestrictFilesAccess(values.RoleIds) ? values.RestrictFilesAccess : false;
        const data: any = {
            DisplayName: values.DisplayName,
            Email: values.Email,
            IsActive: values.IsActive,
            City: values.City ? values.City : null,
            State: values.State ? values.State : null,
            RestrictReportView: canAssociateWithReports(values.RoleIds) ? values.RestrictReportView : false,
            RestrictFilesAccess: restrictFilesAccessValue,
            AllowFileUpload: restrictFilesAccessValue ? values.AllowFileUpload : true,
            RoleIds: values.RoleIds
        };

        try {
            // If user.Id is -1 then this is a new user so hit different endpoint
            const response = isAdd ?
                await makeJSONPostRequest(ApiUrls.REGISTER_USER, dispatch, data) :
                await makeJSONPostRequest(createUrl(ApiUrls.EDIT_USER, { userId: user.id }), dispatch, data);

            if (response.response.ok) {
                if (user.id === getUserId()) {
                    await logOut(dispatch);
                }
                if (needToAssociateWithCustomers(values.IsActive, values.RoleIds)) {
                    updateHasCustomerRole(true);
                    getUser();
                    navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: isAdd ? response.body.userId : user.id, tab: TabValue.USER_CUSTOMERS }));
                } else {
                    navigate(createRoute(ApplicationRoutes.USERS));
                }
                dispatch(SetUserMessageSuccessAction(isAdd ? 'user_success_add_text' : 'user_success_edit_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_failed_save_unauthorized'));
        }
    }

    return (
        <Card className="user-attributes">
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        DisplayName: user.name ?? '',
                        Email: user.email ?? '',
                        IsActive: user.isActive ?? true,
                        City: user.city ?? '',
                        State: user.state ?? '',
                        RestrictReportView: user.restrictReportView ?? false,
                        RestrictFilesAccess: user.restrictFilesAccess ?? false,
                        AllowFileUpload: user.allowFileUpload ?? false,
                        RoleIds: user.roleIds ?? []
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveUser(values).finally(() => {
                            actions.setTouched({});
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <div>
                                    <TextInput name="DisplayName" label="user_attributes_displayName_label" fullwidth={false} />
                                    <TextInput name="Email" label="user_attributes_email_label" fullwidth={false} />
                                </div>
                                <div className="display-grid">
                                    <TextInput name="City" label="user_attributes_city_label" fullwidth={false} />
                                    <StatesDropdown name="State" label="user_attributes_state_label" />
                                </div>
                                <FormControl error={props.touched && Boolean(props.errors.RoleIds)} name="RoleIds" component="fieldset" className="role-checks">
                                    <FormLabel component="legend">{getLabel("user_attributes_roles_label")}</FormLabel>
                                    <FormGroup>
                                        {getRoles(updateUserRoles(props.setFieldValue, props.setFieldTouched))}
                                    </FormGroup>
                                    {props.errors.RoleIds && <FormHelperText className="validateText">{props.errors.RoleIds}</FormHelperText>}
                                </FormControl>
                                <div>
                                    <CheckboxInput name="RestrictReportView" label="user_attributes_restrictReportView_label" disabled={!canAssociateWithReports(props.values.RoleIds)} padded={false} />
                                    <CheckboxInput 
                                        name="RestrictFilesAccess"
                                        label="user_attributes_restrictFilesAccess_label"
                                        disabled={!canRestrictFilesAccess(props.values.RoleIds)}
                                        padded={false}
                                        onToggle={(restrictFilesAccessValue) => {
                                            if (!restrictFilesAccessValue)
                                                props.setFieldValue('AllowFileUpload', false);
                                        }}
                                    />
                                    {props.values.RestrictFilesAccess && <CheckboxInput name="AllowFileUpload" label="user_attributes_allowFileUpload_label" padded={false} />}
                                </div>
                            </div>
                            <div className="flex">
                                <span className="flexIndentLeft"></span>
                                <CheckboxInput name="IsActive" label="user_attributes_isActive_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('user_attributes_cancel_button_label')}</Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={formClean(props.touched) || props.isSubmitting}>{getLabel('user_attributes_submit_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>
    )
}
